import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { GatsbyImage } from "gatsby-plugin-image";

const SeoWhatWeSolve = ({
	heading,
	image,
	imageAlt,
	text,
	imgHeight,
	last,
	buttonUrl,
	buttonText,
}) => {
	return (
		<section className="py-5 pb-lg-7 bg-light-grey">
			<Container>
				<Row>
					<Col>
						<h2 className="display-5 pb-5 text-primary">{heading}</h2>
					</Col>
				</Row>
				<Row>
					<Col>
						<Row className="g-6">
							<Col
								className="d-none d-lg-block"
								lg={{ span: 4, order: last ? "last" : "first" }}
							>
								<div
									style={{
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #0000006A",
										overflow: "hidden",
										maxHeight: imgHeight,
									}}
									className="w-100 "
								>
									<GatsbyImage
										className="w-100 "
										image={image}
										alt={imageAlt}
										objectPosition="top center"
									/>
								</div>
							</Col>
							<Col className="" lg={7}>
								{text}
							</Col>
						</Row>
						{buttonText && (
							<Row>
								<Col className="">
									<Button
										className=" w-100 w-md-auto mt-4  fs-5 white-link py-2 px-4 "
										variant="primary"
										as={Link}
										to={buttonUrl}
									>
										{buttonText}
									</Button>
								</Col>
							</Row>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default SeoWhatWeSolve;
