import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { GatsbyImage } from "gatsby-plugin-image";

const SeoWwaSection = ({
	imgTop,
	imgTopAlt,
	imgBot,
	imgBotAlt,
	heading,
	text,
	buttonText,
	buttonUrl,
	noImages,
	smallHeading,
	spanXl,
	spanLg,
}) => {
	const spanXlNew = spanXl || 12;
	const spanLgNew = spanLg || 12;

	return (
		<section id="results" className="py-5 py-lg-7 bg-white">
			<Container className={`${noImages ? "" : "mb-xl-8"}`}>
				<Row
					className={`${noImages ? "" : "gx-lg-6 gx-xl-7"} ${
						spanLg !== null ? "justify-content-center" : ""
					}`}
				>
					<Col
						className={`${spanLg !== null ? "text-center" : ""}`}
						lg={spanLgNew}
						xl={`${noImages ? spanXlNew : 6}`}
					>
						<p className="ssp-bold text-uppercase text-light-blue">
							{smallHeading}
						</p>
						<h2 className="display-5 text-primary mb-5">{heading}</h2>
						{text}
						<Button
							className=" w-100 w-md-auto mt-4 fs-5 white-link py-2 px-4 "
							variant="primary"
							as={Link}
							to={buttonUrl}
						>
							{buttonText}
						</Button>
					</Col>
					{noImages !== true && (
						<Col lg={6} className="position-relative d-none d-xl-block pb-5">
							<div
								style={{
									borderRadius: " 15px",
									overflow: "hidden",
									boxShadow: "0px 3px 50px #6565656E",
									width: "22rem",
									height: "17rem",
								}}
								className="bg-white  ms-auto mt-4 "
							>
								<GatsbyImage
									className="w-100 "
									image={imgTop}
									alt={imgTopAlt}
								/>
							</div>

							<div
								style={{
									borderRadius: " 15px",
									boxShadow: "0px 3px 90px #00000079",
									overflow: "hidden",
									top: "10rem",
									width: "17rem",
									height: "27rem",
									right: "19.7rem",
								}}
								className="position-absolute  ms-auto  "
							>
								<GatsbyImage
									className="w-100 h-100"
									image={imgBot}
									alt={imgBotAlt}
									objectPosition="top center"
								/>
							</div>
						</Col>
					)}
				</Row>
			</Container>
		</section>
	);
};

export default SeoWwaSection;
