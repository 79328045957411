import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import gsap from "gsap";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const OSSection = ({
	image,
	imageAlt,
	text1,
	link1,
	text2,
	link2,
	text3,
	link3,
	dubai,
}) => {
	const [selectedImage, setSelectedImage] = useState(image);
	const textOne = text1 || "SEO";
	const linkOne = link1 || `${dubai ? "/dubai/seo" : "/seo"}`;
	const textTwo = text2 || "GOOGLE ADS";
	const linkTwo = link2 || `${dubai ? "/dubai/google-ads" : "/google-ads"}`;
	const textThree = text3 || "WEB DEVELOPMENT";
	const linkThree =
		link3 || `${dubai ? "/dubai/web-development" : "/web-development"}`;

	const enterAnimation = (text, img) => {
		if (selectedImage !== img) {
			gsap.to(
				".selected-image",

				{ opacity: 0, duration: 0.5 }
			);
			gsap.to(
				".selected-image",

				{ opacity: 1, duration: 1, delay: 0.6 }
			);
			setTimeout(function () {
				setSelectedImage(img);
			}, 500);
		}

		gsap.to(
			text,

			{ opacity: 1, color: "#FFCA2C", duration: 0.5 }
		);
	};
	const exitAnimation = (text) => {
		gsap.to(
			text,

			{ opacity: 0.69, color: "white", duration: 0.5 }
		);
	};
	return (
		<section className="py-5 py-lg-7 bg-dark-grey">
			<Container>
				<Row className="align-items-center text-center text-lg-start">
					<Col className="d-lg-none" lg={6}>
						<h2 className="display-3 mb-5 text-white">OTHER SERVICES</h2>
						<Link
							to={linkOne}
							className="display-3 mb-5 grow-hover-1 ssp-bold d-block text-decoration-none"
						>
							{textOne}
						</Link>
						<Link
							to={linkTwo}
							className="display-3 mb-5 grow-hover-2 ssp-bold d-block text-decoration-none"
						>
							{textTwo}
						</Link>
						<Link
							to={linkThree}
							className="display-3  mb-5 mb-lg-0 grow-hover-3 ssp-bold d-block text-decoration-none"
						>
							{textThree}
						</Link>
					</Col>
					<Col className="d-none d-lg-block" lg={6}>
						<h2 className="display-3 mb-5 text-white">OTHER SERVICES</h2>
						<Link
							to={linkOne}
							onMouseEnter={() => enterAnimation(".grow-hover-1", image)}
							onMouseLeave={() => exitAnimation(".grow-hover-1")}
							className="display-3 mb-5 grow-hover-1 ssp-bold d-block text-decoration-none"
						>
							{textOne}
						</Link>
						<Link
							to={linkTwo}
							onMouseEnter={() => enterAnimation(".grow-hover-2", image)}
							onMouseLeave={() => exitAnimation(".grow-hover-2")}
							className="display-3 mb-5 grow-hover-2 ssp-bold d-block text-decoration-none"
						>
							{textTwo}
						</Link>
						<Link
							to={linkThree}
							onMouseEnter={() => enterAnimation(".grow-hover-3", image)}
							onMouseLeave={() => exitAnimation(".grow-hover-3")}
							className="display-3  mb-5 mb-lg-0 grow-hover-3 ssp-bold d-block text-decoration-none"
						>
							{textThree}
						</Link>
					</Col>
					<Col>
						<div style={{ overflow: "hidden", borderRadius: "15px" }}>
							<GatsbyImage
								image={selectedImage}
								alt={imageAlt}
								className="w-100"
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default OSSection;
