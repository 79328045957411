import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Marquee from "react-fast-marquee";

const SeoPageLogos = ({ page, heading }) => {
	const data = useStaticQuery(graphql`
		query {
			allWpSeoPageLogo(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					title
					dateGmt
					seoPageLogoFields {
						link
						page
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	return (
		<Container>
			{heading !== null && (
				<Row>
					<Col>
						<h2 className="mb-5 text-primary  text-center">{heading}</h2>
					</Col>
				</Row>
			)}
			<Row className={`${heading !== null ? "mt-4" : ""}`}>
				<Col>
					<Marquee speed="40" gradientColor={[255, 255, 255]}>
						{data.allWpSeoPageLogo.nodes
							.filter((item, index) =>
								item.seoPageLogoFields.page.includes(page)
							)
							.map((logo) => (
								<a
									target="_blank"
									rel="noreferrer"
									href={logo.seoPageLogoFields.link}
								>
									<div className="ps-6 d-flex h-100 align-items-center">
										<GatsbyImage
											loading="eager"
											image={logo.seoPageLogoFields.image.gatsbyImage}
											style={{
												maxWidth: "10rem",
											}}
										/>
									</div>
								</a>
							))}
					</Marquee>
				</Col>
			</Row>
		</Container>
	);
};

export default SeoPageLogos;
